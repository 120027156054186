.App {
  text-align: center;
  color: #FFFFFF;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-header {
  background-color: rgba(0, 0, 0, 0.79);
  padding: 20px;
  flex: 0 1 auto;
}

.App-footer {
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.39);
  flex: 0 1 auto;
}

.App-title {
  font-size: 1.5em;
  display: inline;
}

.App-content {
  flex: 1 1 auto;
  background-color: rgba(0, 0, 0, 0.39);
}

.App-content p {
  margin: 10px 0;
}

.enter-weight button {
  font-size: 16px;
  color: #FFFFFF;
  background-color: #008DC5;
  border: none;
  padding: 3px;
  margin-left: 5px;
}

.enter-weight input {
  font-size: 16px;
  text-align: center;
}

.enter-weight label {
  padding: 5px;
  cursor: pointer;
}

.padding {
  padding: 10px;
}

.input-weight {
  width: 60px;
}

.available-weights {
  padding: 0;
  margin: 0;
}

.available-weights {
  list-style: none;
  margin: 10px 0 0 0;
}

.available-weights li {
  display: inline;
}
