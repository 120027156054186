html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-image: url('/background.jpeg');
  background-attachment: fixed;
  background-position: 80% 50%;
  background-size: cover;
  height: 100vh;
}
